export default [
    {
        title: "General Information",
        subtitle: "General Information",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
    {
        title: "Online Private Lesson",
        subtitle: "Online Private Lesson",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
    {
        title: "Scheduling Online Private Lessons",
        subtitle: "Scheduling",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
    {
        title: "Virtual Tech Camps",
        subtitle: "Virtual Tech Camps",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
    {
        title: "Already Register",
        subtitle: "Virtual Tech Camps",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
    {
        title: "Math Private Lessons",
        subtitle: "Virtual Tech Camps",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
    {
        title: "Courses &  Curriculum",
        subtitle: "Courses &  Curriculum",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
    {
        title: "3D printer",
        subtitle: "3D printer",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
    {
        title: "Small Group Lessons",
        subtitle: "Small Group Lessons",
        faqSegment: [
            {
                question: 'Will Cyberteck hold classes over winter holidays.',
                answer: 'Yes.'
            },
            {
                question: 'Will classes take place on Thanksgiving',
                answer: 'Yes, after having turkey.'
            },
            {
                question: 'Will is the age requirement for iD tech Courses?',
                answer: '3years'
            },
            {
                question: 'When do virtual tech camps start',
                answer: 'Tomorrow'
            },
            {
                question: 'When id tech be hosting in person camps this summer',
                answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
            },
        ]
    },
]