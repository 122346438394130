import react from 'react';




const Fourth_pane = ( ) => (
    
    <div  className="uk-container uk-margin-xlarge-top uk-margin-large-bottom">
             <div className="uk-flex uk-flex-center">
                    <div className="mediumContainer">
                         <div>
                             <h1 className="header1 uk-text-center">Choose your  <span>Virtual Tech Camp</span> Courses</h1>
                             <p className="subText uk-text-center">Game adventure design and animation courses in Scratch or Minecraft or coding own obstacles with LUA. Choose the 
                                 ones that spark your child’s curiosity to explore and build tech know-how.</p>
                                 
                         </div>
                    </div>
             </div>
    </div>
       
);


export default Fourth_pane;